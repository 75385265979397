import { RouteLocationNormalized, Router } from 'vue-router';
import { msalInstance, loginRequest } from '../authConfig';
import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/pull/4188/files/05e237feb7724885306b5554b79b631e134ec80f#diff-d1ded1d6327e313f45c75694bb9ffbb6cee72b393d210bdee2bd593b7d121ca5
namespace GuardService {
  export function registerGuard(router: Router) {
    router.beforeEach(async (to: RouteLocationNormalized) => {
      if (to.meta.requiresAuth) {
        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const shouldProceed = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        return shouldProceed || '/failed';
      }

      return true;
    });
  }

  export async function isAuthenticated(
    instance: PublicClientApplication,
    interactionType: InteractionType,
    loginRequest: PopupRequest | RedirectRequest
  ): Promise<boolean> {
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
    return instance
      .handleRedirectPromise()
      .then(() => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          return true;
        }

        // User is not signed in and attempting to access protected route. Sign them in.
        if (interactionType === InteractionType.Popup) {
          return instance
            .loginPopup(loginRequest)
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        } else if (interactionType === InteractionType.Redirect) {
          return instance
            .loginRedirect(loginRequest)
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        }

        return false;
      })
      .catch(() => {
        return false;
      });
  }
}

export default GuardService;
