import { getBaseUrlForBrukerAdmin } from '@/utils/apiUrls';
import { fetchConfig } from '@/services/configService';
import { TaskTypes } from '@/typings/task/task';
import { UserType } from '@/typings/User/user';

const baseURL = getBaseUrlForBrukerAdmin();
const companyURL = '/Company?searchQuery=';
const userURL = '/User?searchQuery=';
const taskURL = '/Tasks/';

// TODO: Handle error msg and 401:s
// TODO: Create useFetch composable https://vuejs.org/guide/reusability/composables.html#async-state-example
namespace APIServices {
  export const fetchCompanies = () => {
    const companyEndpoint = baseURL + companyURL;

    return fetch(companyEndpoint, fetchConfig('GET')).then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  };

  export const fetchUsersByCompanyId = async function (companyId) {
    const userEndpoint = `${baseURL}/Company/${companyId}/Users`;

    return fetch(userEndpoint, fetchConfig('GET')).then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  };

  export const fetchUserById = async function (guid): Promise<UserType.User> {
    const endpoint = `${baseURL}/User/${guid}`;

    if (!guid) {
      throw new Error('No ID was provided!');
    }

    try {
      const response = await fetch(endpoint, fetchConfig('GET'));
      return response.json();
    } catch {
      throw new Error('Bad Request');
    }
  };

  export const fetchTaskById = function (taskId): Promise<TaskTypes.Task> {
    const endpoint = `${baseURL}/Tasks/${taskId}`;

    if (!taskId) {
      throw new Error('No ID was provided!');
    }

    return fetch(endpoint, fetchConfig('GET'))
      .then((res: Response) => res.json())
      .then((responseJSON) => {
        return responseJSON;
      })
      .catch((e) => {
        console.error('Error', e);
      });
  };

  export const fetchUserOrTaskById = function (item) {
    let endpoint;

    if (item.guid) {
      endpoint = `${baseURL}/User/${item.guid}`;
    } else if (item.taskId) {
      endpoint = baseURL + taskURL + item.taskId;
    }

    if (!endpoint) {
      throw new Error('Bad request!');
    }

    return fetch(endpoint, fetchConfig('GET'))
      .then(async (res: Response) => {
        if (res.status === 200) {
          return res.json();
        }
        return Promise.reject(new Error('Error'));
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.error('Error', e);
      });
  };

  export const fetchBySearchQuery = async (searchQuery) => {
    let endpoint;
    const taskEndpoint = baseURL + taskURL + searchQuery;
    const userEndpoint = baseURL + userURL + searchQuery;

    if (isNaN(parseInt(searchQuery))) {
      endpoint = userEndpoint;
    } else {
      endpoint = taskEndpoint;
    }

    return fetch(endpoint, fetchConfig('GET'))
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        return new Error('Error');
      })
      .catch((e) => {
        console.error('Error', e);
      });
  };

  export const fetchCompanyBySearchQuery = async (searchQuery) => {
    const companyEndpoint = baseURL + companyURL + searchQuery;

    return fetch(companyEndpoint, fetchConfig('GET'))
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        return new Error('Error');
      })
      .catch((e) => {
        console.error('Error', e);
      });
  };

  export const putUser = async function (userGuid, companyId): Promise<Response> {
    const moveUserEndpoint = `${baseURL}/Users/${userGuid}/Move?newUserAdminCompanyId=${companyId}&commit=true`;
    let returnObject;

    await fetch(moveUserEndpoint, fetchConfig('PUT'))
      .then((response) => response)
      .then((result) => (returnObject = result))
      .catch((error) => {
        console.error(error.message);
        throw error;
      });

    return returnObject;
  };

  export const putSubmissionStatus = async function (applicationId): Promise<Response> {
    const submissionStatusEndpoint = `${baseURL}/Applications/${applicationId}/WithdrawSubmission`;
    let returnObject;

    await fetch(submissionStatusEndpoint, fetchConfig('PUT'))
      .then((response) => response)
      .then((result) => (returnObject = result))
      .catch((error) => {
        console.error(error.message);
        throw error;
      });

    return returnObject;
  };

  export const putTask = async function (taskId, fromGuid, toGuid): Promise<Response> {
    const moveTaskEndpoint = `${baseURL}/Tasks/${taskId}/Move?fromUserGuid=${fromGuid}&toUserGuid=${toGuid}&commit=true`;
    let returnObject;

    await fetch(moveTaskEndpoint, fetchConfig('PUT'))
      .then((response) => response)
      .then((result) => (returnObject = result))
      .catch((error) => {
        console.error(error.message);
        throw error;
      });

    return returnObject;
  };

  export const putTasks = async function (fromGuid, toGuid): Promise<Response> {
    const moveTasksEndpoint = `${baseURL}/Tasks/MoveAll?fromUserGuid=${fromGuid}&toUserGuid=${toGuid}&commit=true`;
    let returnObject;

    await fetch(moveTasksEndpoint, fetchConfig('PUT'))
      .then((response) => response)
      .then((result) => (returnObject = result))
      .catch((error) => {
        console.error(error.message);
        throw error;
      });

    return returnObject;
  };
}

export default APIServices;
