
import { defineComponent } from 'vue';
import { AuxCard } from '@ambita/design-system';

export default defineComponent({
  name: 'SearchResultsCard.vue',
  components: {
    AuxCard,
  },
  props: {
    value: Object,
    index: Number,
  },
  setup(props) {
    return { props };
  },
});
