export const fetchConfig = function (method): RequestInit {
  return {
    mode: 'cors',
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
};

const getAuthToken = function () {
  return localStorage.getItem('Support-User');
};
