<template>
  <div>
    <input
      class="searchBar"
      type="text"
      placeholder="Søk..."
      :value="modelValue"
      @keyup="onKeyUp"
    />
  </div>
</template>

<script>
import { defineProps, defineEmits } from 'vue';

export default {
  name: 'SearchBar.vue',
};
</script>

<script setup>
defineProps({
  modelValue: String,
});

const emit = defineEmits(['update:modelValue', 'search']);

const onKeyUp = (event) => {
  emit('search', event.target.value);
  emit('update:modelValue', event.target.value);
};
</script>

<style scoped>
.searchBar {
  position: fixed;
  width: 700px;
  margin-left: 250px;
  z-index: 1002;
  height: 40px;
  background-color: #ffffff;
  margin-top: -45px;
}
</style>
