import { createRouter, createWebHistory, RouteParams, RouteRecordRaw } from 'vue-router';
import UserCard from '@/components/Cards/UserCard/UserCard.vue';
import MoveTaskCard from '@/components/Cards/MoveTaskCard/MoveTaskCard.vue';
import LoginPage from '@/components/LoginPage/LoginPage.vue';
import MoveUserCard from '@/components/Cards/MoveUserCard/MoveUserCard.vue';
import Failed from '@/components/Failed/Failed.vue';
import GuardService from '@/router/Guard';

// TODO: Add MoveUser and Tasks route under User route as children
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: LoginPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/:guid',
    name: 'User',
    component: UserCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/move/:guid',
    name: 'MoveUser',
    component: MoveUserCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/task/:taskId',
    name: 'Task',
    component: MoveTaskCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tasks/:guid',
    name: 'Tasks',
    component: MoveTaskCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/failed',
    name: 'Failed',
    component: Failed,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

GuardService.registerGuard(router);

export namespace RouterService {
  export const routeTo = async function (
    pathName: string,
    routeParams?: RouteParams
  ): Promise<void> {
    if (!pathName) {
      throw new Error('The route pathname is missing');
    } else if (!routeParams) {
      await router.push({ name: pathName });
    } else {
      await router.push({ name: pathName, params: routeParams });
    }
  };

  export const routeBack = async function (): Promise<void> {
    await router.go(-1);
  };
}

export default router;
